export const PARTNER_ID = "2";
export const CLIENT_ID = "ujaePi2haen6ioh4egaCoo8Jio6ii2ye";
export const ACQUIRING_API_BASE_URL = "https://api.charism.ai/acquiring";
export const APPROVER_API_BASE_URL = "https://api.charism.ai";
export const CARD_HOLDER_API_BASE_URL = "https://api.charism.ai";
export const CURRENCY_API_BASE_URL = "https://api.charism.ai/currency";
export const REGISTRATION_API_BASE_URL = "https://api.charism.ai/reg";
export const WALLET_API_BASE_URL = "https://api.charism.ai/wallet";
export const ZEN_DESK_ID = "6083da3b-a66c-4c6f-b8e4-789efa9f7e91";
export const ZEN_DESK_NAME = "Banqeta";
export const EMAIL_HELP = "help@charism.io";
export const EMAIL_HELLO = "hello@banqeta.com";
