var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from "mobx";
import { acquiringApi } from "@/api/acquiring";
import { InvoicePaymentProviderType } from "@/api/acquiring/types";
import { registrationApi } from "@/api/registration";
import { InvoiceStatus, PaymentStatus, RecurrentInvoiceStatus } from "@/api/registration/types";
import { STAND_URL } from "@/config";
import { parseApiError } from "@/shared/utils/error";
import { Reactions } from "@/shared/utils/reactions";
export class SubscriptionStore {
    constructor() {
        this.loader = 0;
        this.ready = false;
        this.isPaid = false;
        this.applyErrors = [];
        this.subscription = undefined;
        this.reactions = new Reactions();
        makeAutoObservable(this, {}, { autoBind: true });
    }
    init() {
        this.sync();
    }
    sync() {
        return __awaiter(this, void 0, void 0, function* () {
            runInAction(() => {
                this.loader += 1;
            });
            let subscription;
            let isPaid;
            try {
                const subscriptions = yield registrationApi.subscription.getSubscriptions();
                let current = subscriptions.data
                    .filter((item) => item.status === RecurrentInvoiceStatus.INIT)
                    .at(0);
                if (!current && subscriptions.data.length > 0) {
                    current = subscriptions.data.at(0);
                }
                if (subscriptions.data.some((_) => [
                    RecurrentInvoiceStatus.TRIAL,
                    RecurrentInvoiceStatus.ACTIVE,
                ].includes(_.status))) {
                    isPaid = true;
                }
                if (current === null || current === void 0 ? void 0 : current.id) {
                    const result = yield registrationApi.subscription.getSubscriptionById(current.id);
                    subscription = result.data;
                }
            }
            catch (e) {
                console.error(e);
            }
            runInAction(() => {
                this.loader -= 1;
                this.ready = true;
                this.subscription = subscription;
                this.isPaid = isPaid;
            });
        });
    }
    select(detailsId) {
        return __awaiter(this, void 0, void 0, function* () {
            let success = false;
            runInAction(() => {
                this.loader += 1;
            });
            try {
                yield registrationApi.subscription.startSubscription({
                    subscriptionDetailsId: detailsId,
                });
                yield this.sync();
                success = true;
            }
            catch (e) {
                console.error(e);
            }
            runInAction(() => {
                this.loader -= 1;
            });
            return success;
        });
    }
    createFailedRed(ckoPay, ckoSess, ckoPaySess) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            runInAction(() => {
                this.loader += 1;
            });
            yield this.sync();
            try {
                if (!((_a = this.invoice) === null || _a === void 0 ? void 0 : _a.id)) {
                    throw new Error("invoice.id must be defined");
                }
                yield acquiringApi.payment.checkoutFailedRedirect({
                    "cko-payment-id": ckoPay,
                    "cko-session-id": ckoSess,
                    "cko-payment-session-id": ckoPaySess,
                }, {
                    headers: {
                        referer: STAND_URL,
                    },
                });
                yield this.sync();
            }
            catch (e) {
                console.error(e);
            }
            runInAction(() => {
                this.loader -= 1;
            });
        });
    }
    createSuccessRed(ckoPay, ckoSess, ckoPaySess) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            runInAction(() => {
                this.loader += 1;
            });
            yield this.sync();
            try {
                if (!((_a = this.invoice) === null || _a === void 0 ? void 0 : _a.id)) {
                    throw new Error("invoice.id must be defined");
                }
                yield acquiringApi.payment.checkoutSuccessRedirect({
                    "cko-payment-id": ckoPay,
                    "cko-session-id": ckoSess,
                    "cko-payment-session-id": ckoPaySess,
                }, {
                    headers: {
                        referer: STAND_URL,
                    },
                });
                yield this.sync();
            }
            catch (e) {
                console.error(e);
            }
            runInAction(() => {
                this.loader -= 1;
            });
        });
    }
    createPayment() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            runInAction(() => {
                this.loader += 1;
            });
            yield this.sync();
            try {
                if (!((_a = this.invoice) === null || _a === void 0 ? void 0 : _a.id)) {
                    throw new Error("invoice.id must be defined");
                }
                yield acquiringApi.invoice.paymentGetOrCreate({
                    type: InvoicePaymentProviderType.CHECKOUT,
                    invoiceId: this.invoice.id,
                });
                yield this.sync();
            }
            catch (e) {
                console.error(e);
            }
            runInAction(() => {
                this.loader -= 1;
            });
        });
    }
    applyPromo(discountCode) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let applyErrors = [];
            runInAction(() => {
                this.loader += 1;
            });
            try {
                if (!((_a = this.invoice) === null || _a === void 0 ? void 0 : _a.id)) {
                    throw new Error("invoice.id must be defined");
                }
                yield acquiringApi.invoice.applyDiscount(this.invoice.id, { discountCode });
                if (this.payment) {
                    yield acquiringApi.invoice.updatePayment({
                        invoiceId: this.invoice.id,
                        type: InvoicePaymentProviderType.CHECKOUT,
                        paymentId: this.payment.id,
                    });
                }
                yield this.sync();
            }
            catch (e) {
                console.error(e);
                applyErrors = parseApiError(e);
            }
            runInAction(() => {
                this.loader -= 1;
                this.applyErrors = applyErrors;
            });
        });
    }
    get id() {
        var _a;
        return (_a = this.subscription) === null || _a === void 0 ? void 0 : _a.id;
    }
    get invoice() {
        var _a;
        return (_a = this.subscription) === null || _a === void 0 ? void 0 : _a.linkedInvoices.filter((item) => item.status === InvoiceStatus.PENDING
            || item.status === InvoiceStatus.INIT
            || item.status === InvoiceStatus.PROCESSING).at(0);
    }
    get payment() {
        var _a, _b;
        return (_b = (_a = this.invoice) === null || _a === void 0 ? void 0 : _a.payments) === null || _b === void 0 ? void 0 : _b.filter((item) => (item.status === PaymentStatus.INIT
            || item.status === PaymentStatus.PROCESSING)
            && "session" in item).at(0);
    }
    get session() {
        if (this.payment && "session" in this.payment) {
            if (this.payment.session && "body" in this.payment.session) {
                return this.payment.session.body;
            }
        }
        return undefined;
    }
    get amount() {
        var _a;
        return (_a = this.invoice) === null || _a === void 0 ? void 0 : _a.amount;
    }
    get oldAmount() {
        var _a;
        return (_a = this.invoice) === null || _a === void 0 ? void 0 : _a.AmountBeforeDiscount;
    }
    get currency() {
        var _a;
        return (_a = this.invoice) === null || _a === void 0 ? void 0 : _a.currency;
    }
    get loading() {
        return this.loader > 0;
    }
    get subscriptionPaid() {
        return this.isPaid;
    }
}
