var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
import { parseApiError } from "@/shared/utils/error";
export class PaymentPromoStore {
    constructor(subscription) {
        this.subscription = subscription;
        this.discounts = [];
        this.code = undefined;
        this.loader = 0;
        this.discountErrors = [];
        makeAutoObservable(this, {}, { autoBind: true });
    }
    init() {
        this.syncDiscounts();
    }
    syncDiscounts() {
        return __awaiter(this, void 0, void 0, function* () {
            let discounts = [];
            let discountErrors = [];
            runInAction(() => {
                this.loader += 1;
            });
            try {
                const discountsResp = yield registrationApi.discounts.getDiscounts();
                discounts = discountsResp.data;
            }
            catch (e) {
                discountErrors = parseApiError(e);
                console.error(e);
            }
            runInAction(() => {
                var _a;
                this.discountErrors = discountErrors;
                this.discounts = discounts;
                this.code = (_a = this.discountHighest) === null || _a === void 0 ? void 0 : _a.code;
                this.loader -= 1;
            });
        });
    }
    submitDiscount() {
        return __awaiter(this, void 0, void 0, function* () {
            let discountErrors = [];
            runInAction(() => {
                this.loader += 1;
            });
            try {
                if (!this.code) {
                    throw new Error("Code must be defined");
                }
                yield this.subscription.applyPromo(this.code);
                yield this.syncDiscounts();
            }
            catch (e) {
                discountErrors = parseApiError(e);
                console.error(e);
            }
            runInAction(() => {
                this.loader -= 1;
                this.discountErrors = discountErrors;
            });
        });
    }
    // if more than 1 promo code, we should use highest, business logic
    get discountHighest() {
        if (this.discounts.length === 0)
            return undefined;
        const percentArr = this.discounts.map((_) => { var _a; return (_a = _.discount_percent) !== null && _a !== void 0 ? _a : 0; });
        const index = percentArr.indexOf(Math.max(...percentArr));
        return this.discounts[index];
    }
    get discount() {
        if (this.discounts.length === 0)
            return undefined;
        return this.discounts[0];
    }
    get loading() {
        return this.loader > 0 || this.subscription.loading;
    }
    get error() {
        return [...this.discountErrors, ...this.subscription.applyErrors];
    }
}
