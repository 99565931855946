import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath } from "react-router-dom";
import { AuthPageStore } from "@/pages/AuthPage/stores/AuthPageStore";
import { SignupPageStore } from "@/pages/SignupPage/stores/SignupPageStore";
import { ROUTES } from "@/routes";
import { FormControlCheckbox, FormControlInput } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { Link } from "@/shared/components/Link";
import { PasswordHint } from "@/shared/components/PasswordHint";
import { Typography } from "@/shared/components/Typography";
import mainCustomerAgreement from "@/shared/files/main_customer_agreement.pdf";
import privacyPolicy from "@/shared/files/privacy_policy.pdf";
import termsOfUse from "@/shared/files/terms_of_use.pdf";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { onChange, onCheckbox } from "@/shared/utils/input";
export const SignupForm = observer(() => {
    var _a, _b, _c, _d;
    const page = useStoreContext(SignupPageStore);
    const auth = useStoreContext(AuthPageStore);
    const media = useMedia();
    return (React.createElement(AuthForm, { onBack: media.tablet ? auth.toggleForm : undefined, onSubmit: page.submitEmail, title: "Create an account", desc: React.createElement(React.Fragment, null,
            "Already have?",
            " ",
            React.createElement(Link, { type: "text16Bold", to: generatePath(ROUTES.signin) }, "Sign in")), content: React.createElement(React.Fragment, null,
            React.createElement(FormControlInput, { label: "Email", value: page.email, onChange: onChange(action((value) => {
                    page.email = value;
                })), variantError: "alert", error: (_a = page.errorsByKey.email) === null || _a === void 0 ? void 0 : _a[0], disabled: page.loading }),
            React.createElement(FormControlInput, { type: "password", label: "Password", value: page.password, onChange: onChange(action((value) => {
                    page.password = value;
                })), variantError: "alert", error: (_b = page.errorsByKey.password) === null || _b === void 0 ? void 0 : _b[0], disabled: page.loading }),
            React.createElement(PasswordHint, { digit: page.passwordValidation.digit, length: page.passwordValidation.length, lowercase: page.passwordValidation.lowercase, special: page.passwordValidation.special, uppercase: page.passwordValidation.uppercase }),
            React.createElement(FormControlInput, { type: "referral", label: "Referral code", error: (_c = page.errorsByKey.referral) === null || _c === void 0 ? void 0 : _c[0], variantError: "alert", disabled: page.loading, value: page.referral, onChange: onChange(action((value) => {
                    page.referral = value;
                })) })), footer: React.createElement(React.Fragment, null,
            React.createElement(FormControlCheckbox, { checked: page.terms, onChange: onCheckbox(action((value) => {
                    page.terms = value;
                })), label: React.createElement(Typography, { type: "text12", color: "black-500" },
                    "By proceeding, you agree to our ",
                    React.createElement("a", { href: termsOfUse }, "Terms of Use"),
                    ", our",
                    " ",
                    React.createElement("a", { href: mainCustomerAgreement }, "Main Customer Agreement"),
                    ", and that you have read and understood our ",
                    React.createElement("a", { href: privacyPolicy }, "Privacy Policy")) }),
            React.createElement(Button, { type: "submit", width: "100%", error: (_d = page.errorsByKey.unknown) === null || _d === void 0 ? void 0 : _d[0], disabled: !page.isSignupFormValid || page.loading }, "Create an account")) }));
});
