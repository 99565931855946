import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { Accounts } from "./components/Accounts";
import { useGetVerificationInfo } from "@/api/registration";
import { VerificationStatus } from "@/api/registration/types";
import { VerificationBanner } from "@/entities/Verification";
import { Balances } from "@/pages/HomePage/components/Balances";
import { TotalBalance } from "@/pages/HomePage/components/TotalBalance";
import { ModalType } from "@/routes";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { ReconcilingTransfer } from "@/widgets/ReconcilingTransfer";
export const HomePage = observer(() => {
    const { data } = useGetVerificationInfo();
    const [search] = useSearchParams();
    const status = data === null || data === void 0 ? void 0 : data.status;
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px" },
        search.get("type") === ModalType.AfterKyb && React.createElement(ReconcilingTransfer, null),
        status === VerificationStatus.GREEN && (React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
            React.createElement(Box, null,
                React.createElement(Typography, { type: "text44Bold", variant: "h1" }, "Welcome"),
                React.createElement(Typography, { type: "text16", color: "black-500", variant: "body1" }, "This is your company's dashboard")))),
        status === VerificationStatus.INIT && (React.createElement(Box, { display: "grid", gridTemplateColumns: "1fr", gap: "20px" },
            React.createElement(VerificationBanner, null))),
        status === VerificationStatus.GREEN
            ? (React.createElement(React.Fragment, null,
                React.createElement(TotalBalance, null),
                React.createElement(Box, { display: "flex", flexDirection: "column" },
                    React.createElement(Accounts, null)),
                React.createElement(Balances, null)))
            : (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px", alignItems: "center" },
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px", position: "relative", width: "100%" },
                    React.createElement(TotalBalance, null),
                    React.createElement(Box, { display: "grid", gridTemplateColumns: "1fr 1fr", gap: "40px", marginBottom: "40px" },
                        React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                            React.createElement(Typography, { type: "text24" }, "Bank accounts"),
                            React.createElement(Typography, { type: "text14" }, "View All")),
                        React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                            React.createElement(Typography, { type: "text24" }, "Pending transactions"),
                            React.createElement(Typography, { type: "text14" }, "View All"))),
                    React.createElement(StyledOverlay, { position: "absolute", top: "0", left: "0", width: "100%", height: "100%" })),
                React.createElement(Box, { padding: "30px", display: "flex", flexDirection: "column", bgColor: "general-white", borderRadius: "22px", alignItems: "center", justifyContent: "center", mb: "40px" },
                    React.createElement(BaseIcon, { size: 24, icon: "Lock" }),
                    React.createElement(Typography, { color: "black-500", textAlign: "center", maxWidth: "440px" }, "Unlock full access to our business banking services by completing the company and identity verification process."))))));
});
const StyledOverlay = styled(Box)(() => ({
    background: "linear-gradient(180deg, rgba(246, 247, 248, 0.4) 0%, #F6F7F8 100%)",
}));
