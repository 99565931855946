import * as React from "react";
import { IdDocSubType, IdDocType } from "@/api/registration/types";
import { IndividualDocument } from "@/pages/KYBPage/components/Document/IndividualDocument";
import { IndividualVerificationStore } from "@/pages/KYBPage/stores/IndividualVerificationStore";
import { Box } from "@/shared/components";
import { Typography } from "@/shared/components/Typography";
import { IndividualTypes } from "@/shared/constants/dict";
import { useProvider, useStore } from "@/shared/hooks/useStore";
export const IndividualDocs = ({ data, }) => {
    var _a, _b;
    const individualVerification = useStore(IndividualVerificationStore, data.id, (_a = data.verificationIds) === null || _a === void 0 ? void 0 : _a[0]);
    const IndividualVerificationProvider = useProvider(IndividualVerificationStore);
    return (React.createElement(IndividualVerificationProvider, { value: individualVerification },
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px" },
            React.createElement(Typography, { type: "text20", fontWeight: 500 },
                data.firstName,
                " ",
                data.lastName,
                " ",
                React.createElement(Typography, { color: "black-400", type: "text20" }, (_b = IndividualTypes.find(i => i.key === data.individualType)) === null || _b === void 0 ? void 0 : _b.label)),
            React.createElement(IndividualDocument, { label: "Passport or ID Card", type: IdDocType.PASSPORT }),
            React.createElement(IndividualDocument, { label: "Selfie with Passport or ID Card", type: IdDocType.SELFIE }),
            React.createElement(IndividualDocument, { label: "Proof of Address (UBO)", type: IdDocType.OTHER, subType: IdDocSubType.PROOF_OF_ADDRESS }))));
};
